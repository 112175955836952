<template>
  <div>
    <div class="right-menu shipping-right" v-loading.fullscreen.lock="scriptLoader">

      <subHeader pageName="HELP" />

      <tutorialsAction moduleName="HELP"/>
   
      <div class="db-container">
        <div id="hubspotForm" v-once></div>

        <div class="whatsappIcon" v-if="this.countryCheckLoader">
          <vue-skeleton-loader
            type="circle"
            :width="60"
            :height="60"
            animation="fade"
          />
        </div>

        <div class="whatsappIcon">
          <a :href="countryIsUSA ? `https://wa.me/13142978112` : `https://wa.me/923164000277`" target="_blank">
            <img src="@/assets/images/whatsapp_logo.png" alt="whatsapp_icon">
          </a>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";

export default {
  name: 'help',
  data:()=>({
    scriptLoader:false,
    countryIsUSA:false,
    countryCheckLoader:false,
  }),
  components:{
    subHeader,
    tutorialsAction
  },
  methods:{
    // loadHubSpotScript() {

    //   const script = document.createElement('script');

    //   script.className = "hubSpotChat"

    //   script.src = '//js.hs-scripts.com/23833786.js'; // Replace with your HubSpot script URL
      
    //   script.async = true;

    //   document.head.appendChild(script);

    // },
    loadHubSpotFormScript(){

      const script = document.createElement("script");

      script.src="//js.hsforms.net/forms/embed/v2.js";

      script.id = "hubSpotFormScript"

      document.body.appendChild(script);

      script.addEventListener("load", () => {

        if(window.hbspt){

          window.hbspt.forms.create({
            portalId: "23833786",
            formId: "84c9c053-5573-4fe5-925d-df445ed23bb2",
            target: "#hubspotForm"
          })

        }

        this.scriptLoader = false

      })

    }
  },
  mounted() {

    this.scriptLoader = true

    // this.loadHubSpotScript();

    this.loadHubSpotFormScript();

  },
  async beforeMount(){

    if(document.body.classList.contains('hideChat')){

      document.body.classList.remove('hideChat')

    }

    this.countryCheckLoader = true
    try{

      let countryRes = await this.$axios.get('/my-country');
      if(countryRes.data.status_code == "1300"){
          
        this.countryCheckLoader = false

        if(countryRes.data.country == 'United States' || countryRes.data.country == 'Canada'){
          this.countryIsUSA = true
        }

      }

    }catch(error){

      this.countryCheckLoader = false

    }


  },
  beforeDestroy(){
    
  },
  beforeRouteLeave(to, from, next) {

    if(!document.body.classList.contains('hideChat')){
      document.body.classList.add('hideChat');
    }

    next();

  }
}
</script>
<style scoped>
  .whatsappIcon{
    position: fixed;
    bottom: 1.5%;
    width: 60px;
    height: 60px;
  }
  .whatsappIcon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>